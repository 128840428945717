import { configureStore } from '@reduxjs/toolkit';
import playersReducer from './config/PlayersSlice';
import { PlayersApi } from './config/PlayersApi';
import { SeasonsApi } from './config/SeasonsApi';
import { MatchesApi } from './config/MatchesService';
import { GroupsApi } from './config/GroupsService';

export const store = configureStore({
  reducer: {
    players: playersReducer,
    [PlayersApi.reducerPath]: PlayersApi.reducer,
    [SeasonsApi.reducerPath]: SeasonsApi.reducer,
    [MatchesApi.reducerPath]: MatchesApi.reducer,
    [GroupsApi.reducerPath]: GroupsApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      PlayersApi.middleware,
      SeasonsApi.middleware,
      MatchesApi.middleware,
      GroupsApi.middleware
    ) // add middleware
});
