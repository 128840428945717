import { Link } from 'react-router-dom';
import { useGetAllSeasonsQuery } from '../config/SeasonsApi';

export const SeasonsPage = () => {
  const { isLoading, data: seasons } = useGetAllSeasonsQuery();

  return (
    <div>
      This is seasons page
      <div>List of Seasons</div>
      <ul>
        {seasons &&
          seasons?.map((season) => {
            let data = { ...season.attributes };
            return (
              <li key={season?.id}>
                <Link to={`${season?.id}`}>{data?.season_name}</Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
};
