import { useParams } from 'react-router-dom';
import { Table } from '../components/Table';

import { useGetAllGroupsQuery } from '../config/SeasonsApi';

export const StandingsPage = () => {
  const columnDefs = [
    { id: 1, field: 'ranking', headerName: 'Ranking', flex: 1 },
    { id: 2, field: 'player_name', headerName: 'First Name', flex: 4 },
    { id: 4, field: 'email', flex: 3 },
    { id: 5, field: 'contact', flex: 2 },
    { id: 6, field: 'age', flex: 1 }
  ];

  const { isLoading, data: groups } = useGetAllGroupsQuery(3);

  console.log(groups);

  const rowData = [
    {
      player_name: 'Ojas J',
      email: 'something@gmal.com',
      contact: '3320222',
      age: '20',
      plays: 'right-handed'
    },
    {
      player_name: 'Bhushan J',
      email: 'something@gmal.com',
      contact: '3320222',
      age: '20',
      plays: 'right-handed'
    }
  ];

  return (
    <section className="section schedule">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h3>
                Latest Season <span className="alternate">Standings</span>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do
                eiusm tempor incididunt ut labore
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="schedule-tab">
              <ul className="nav nav-pills text-center">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    href="#nov20"
                    data-toggle="pill"
                  >
                    Day-01
                    <span>20 November 2017</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#nov21" data-toggle="pill">
                    Day-02
                    <span>21 November 2017</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#nov22" data-toggle="pill">
                    Day-03
                    <span>22 November 2017</span>
                  </a>
                </li>
              </ul>
            </div>
            <div className="schedule-contents bg-schedule">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active schedule-item"
                  id="nov20"
                >
                  {/* {/* <!-- Headings --> */}
                  {/* <Table
                    columnDefs={columnDefs}
                    rowData={groups?.map((player) => player.attributes)}
                  /> */}
                </div>
              </div>
            </div>

            <div className="download-button text-center">
              <a href="/" className="btn btn-main-md">
                Download Standings
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
