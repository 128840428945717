import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const GroupsApi = createApi({
  reducerPath: 'GroupsApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:1337/api/groups/' }),
  endpoints: (builder) => ({
    getAllGroups: builder.query({
      query: () => ({
        url: `?populate[matches][populate]=*`,
        params: {}
      }),
      transformResponse: (response) => response.data
    })
  })
});

export const { useGetAllGroupsQuery } = GroupsApi;
