import moment from 'moment';

export const MatchBody = ({ match, id }) => {
  return (
    <div className="content text-left">
      <div className="card-header">
        <div className="text-left">
          <div className="font-bold">{match.match_name}</div>
          <div>{match.group.data.attributes.groupName}</div>
        </div>
      </div>
      <ul className="list-group list-group-flush">
        {match?.teams.map((team) => (
          <li className="list-group-item" key={team.id}>
            <div
              style={{
                fontWeight: team.is_winner ? 'bold' : 'inherit'
              }}
              className="col-md-8 pl-0"
            >
              <span
                className={`fa fa-check-circle available pr-1 ${
                  team.is_winner ? 'winner visible' : 'invisible'
                }`}
              ></span>
              <span>{team.player?.data?.attributes?.player_name}</span>
            </div>
            <div className="col-md-4 text-right pr-0">
              {team.sets.map((set, index) => (
                <span
                  className={set.set_win ? 'font-bold p-2' : 'p-2'}
                  key={index}
                >
                  {set.score}
                </span>
              ))}
            </div>
          </li>
        ))}
      </ul>
      <div className="card-footer text-muted text-right">
        {moment(match.match_date).format('MMMM Do YYYY')}
      </div>
    </div>
  );
};
