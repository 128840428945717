import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const PlayersApi = createApi({
  reducerPath: 'playersApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:1337/api/players/' }),
  endpoints: (builder) => ({
    getAllPlayers: builder.query({
      query: () => ({
        url: '?&populate[groups][fields]=groupName&populate[profile_picture][fields][1]=url',
        params: {}
      }),
      transformResponse: (response) => response.data
    }),
    getPlayerDetails: builder.query({
      query: (id) => ({
        url: `${id}?&populate[groups][fields]=groupName&populate[profile_picture][fields][1]=url`,
        params: { id }
      }),
      transformResponse: (response) => response.data.attributes
    })
  })
});

export const { useGetAllPlayersQuery, useGetPlayerDetailsQuery } = PlayersApi;
