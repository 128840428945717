import { Outlet } from 'react-router-dom';
import { Navigation } from './components/Navigation';

export const Layout = () => {
  return (
    <div>
      <Navigation />
      <section className="section speakers white mt-5">
        <Outlet />
      </section>
      <footer>Tennisoft</footer>
    </div>
  );
};
