import { CardBody } from './CardBody';
import { MatchBody } from './MatchBody';

export const Card = ({ data, id, type }) => {
  return (
    <div className="speaker-item">
      {type === 'player' ? (
        <CardBody player={data} id={id} />
      ) : (
        <MatchBody match={data} id={id} />
      )}
    </div>
  );
};
