import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const SeasonsApi = createApi({
  reducerPath: 'SeasonsApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:1337/api/seasons/' }),
  endpoints: (builder) => ({
    getAllSeasons: builder.query({
      query: () => ({
        url: '',
        params: {}
      }),
      transformResponse: (response) => response.data
    }),
    getAllGroups: builder.query({
      query: (id) => ({
        url: `${id}?populate[groups][populate][0]=players`,
        params: { id }
      }),
      transformResponse: (response) => {
        const rawData = { ...response.data.id, ...response.data.attributes };
        const groups = response.data.attributes.groups.data;
        return { ...rawData, groups };
      }
    })
  })
});

export const { useGetAllSeasonsQuery, useGetAllGroupsQuery } = SeasonsApi;
