import { useGetAllPlayersQuery } from '../config/PlayersApi';
import { Card } from './Card';

export const Players = () => {
  const { data: players } = useGetAllPlayersQuery();

  return (
    <section className="section speakers white">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h3>
                Who <span className="alternate">are Playing?</span>
              </h3>
              <p>We are happy to introduce you to our members.</p>
            </div>
          </div>
        </div>
        <div className="row">
          {players &&
            players.map((player) => (
              <div className="col-lg-3 col-md-4 col-sm-6" key={player.id}>
                <Card
                  data={player?.attributes}
                  id={player?.id}
                  type={'player'}
                />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
