import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import { PlayersPage } from './pages/Players';
import { SeasonsPage } from './pages/Seasons';
import { StandingsPage } from './pages/Standings';
import { MatchesPage } from './pages/Matches';
import { SeasonPage } from './pages/Season';
import { PlayerDetailsPage } from './pages/PlayerDetails';

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="players" element={<PlayersPage />} />
          <Route path="players/:id" element={<PlayerDetailsPage />} />
          <Route path="seasons" element={<SeasonsPage />} />
          <Route path="seasons/:id" element={<SeasonPage />} />
          <Route path="standings" element={<StandingsPage />} />
          <Route path="matches" element={<MatchesPage />} />
          <Route path="matches/:groupId" element={<MatchesPage />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

export const NoMatch = () => {
  return <h1>Oops! No data found!</h1>;
};

export default App;
