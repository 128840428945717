import { Link } from 'react-router-dom';
import { useGetAllSeasonsQuery } from '../config/SeasonsApi';

export const Navigation = () => {
  const { data: seasons } = useGetAllSeasonsQuery();
  return (
    <nav className="navbar main-nav border-less fixed-top navbar-expand-lg p-0">
      <div className="container-fluid p-0">
        <a className="navbar-brand" href="index.html">
          <img src="images/logo.png" alt="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item dropdown active">
              <Link to="/" className="nav-link">
                Home
                <span>/</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="players" className="nav-link">
                Players
                <span>/</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="standings" className="nav-link">
                Standings<span>/</span>
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link to="seasons" className="nav-link" data-toggle="dropdown">
                Seasons
                <i className="fa fa-angle-down"></i>
                <span>/</span>
              </Link>{' '}
              {/* <!-- Dropdown list --> */}
              <ul className="dropdown-menu">
                {seasons &&
                  seasons?.map((season) => {
                    let data = { ...season.attributes };
                    return (
                      <div key={season?.id}>
                        {data?.visible ? (
                          <li>
                            <Link
                              to={`seasons/${season?.id}`}
                              className="nav-link"
                            >
                              {data?.season_name}
                            </Link>
                          </li>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
              </ul>
            </li>
            <li className="nav-item">
              <Link to="matches" className="nav-link">
                Matches<span>/</span>
              </Link>
            </li>
          </ul>
          <a href="contact.html" className="ticket">
            <span>Login</span>
          </a>
        </div>
      </div>
    </nav>
  );
};
