import { Link } from 'react-router-dom';
export const Group = ({ group }) => {
  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="pricing-item featured">
        <div className="pricing-heading">
          {/* <!-- Title --> */}
          <div className="title">
            <h6>{group?.groupName}</h6>
          </div>
        </div>
        <div className="pricing-body">
          {/* <!-- Feature List --> */}
          <ul className="feature-list m-0 p-0">
            {group?.players?.data.map((player) => {
              let data = { ...player.attributes };
              return (
                <li key={player?.id}>
                  <p>
                    <span className="fa fa-check-circle available"></span>
                    {data.player_name}
                  </p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="pricing-footer text-center">
          <Link to="/standings" className="btn btn-transparent-md">
            View Standings
          </Link>
        </div>
      </div>
    </div>
  );
};
