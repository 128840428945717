import { useParams } from 'react-router-dom';
import { useGetPlayerDetailsQuery } from '../config/PlayersApi';
import { DATA_IMAGE_URL } from '../config/constants';

export const PlayerDetailsPage = () => {
  const { id } = useParams();
  const { data: player } = useGetPlayerDetailsQuery(id);
  const moreDetails = [
    'age',
    'email',
    'contact',
    'plays',
    'date_joined',
    'coach'
  ];
  return (
    <section className="section single-speaker">
      <div className="container">
        <div className="block">
          <div className="row">
            <div className="col-lg-5 col-md-6 align-self-md-center">
              <div className="image-block">
                <img
                  src={`${DATA_IMAGE_URL}${player?.profile_picture?.data?.attributes?.url}`}
                  className="img-fluid"
                  alt="speaker"
                />
              </div>
            </div>
            <div className="col-lg-7 col-md-6 align-self-center">
              <div className="content-block">
                <div className="name">
                  <h3>{player?.player_name}</h3>
                </div>
                <div className="profession">
                  <p>{player?.groups?.data[0]?.attributes?.groupName}</p>
                </div>
                <div className="details">
                  <ul className="category-list m-0 p-0">
                    {moreDetails.map((key, index) =>
                      player?.[key] ? (
                        <li key={index}>
                          <b>{key.replace('_', ' ')}</b> :{' '}
                          <span className="alternate">{player?.[key]}</span>
                        </li>
                      ) : (
                        ''
                      )
                    )}
                  </ul>
                </div>
                <div className="social-profiles">
                  <h5>Experience</h5>
                  <p>{player?.experience}</p>
                  <ul className="list-inline social-list">
                    {/* <li className="list-inline-item">
                                  <a href="#"><i className="fa fa-twitter"></i></a>
                              </li>
                              <li className="list-inline-item">
                                  <a href="#"><i className="fa fa-linkedin"></i></a>
                              </li>
                              <li className="list-inline-item">
                                  <a href="#"><i className="fa fa-facebook"></i></a>
                              </li>
                              <li className="list-inline-item">
                                  <a href="#"><i className="fa fa-skype"></i></a>
                              </li>
                              <li className="list-inline-item">
                                  <a href="#"><i className="fa fa-pinterest-p"></i></a>
                              </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="block-2">
          <div className="row">
            <div className="col-md-6">
              <div className="personal-info">
                <h5>Matches List</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Excepturi explicabo suscipit deleniti voluptatum quos nostrum
                  iure doloremque cupiditate voluptatem a enim eaque quod
                  perspiciatis repudiandae, mollitia adipisci ea, quidem eveniet
                  consequatur veniam error. Adipisci, suscipit corporis
                  repellat, soluta vitae deserunt perspiciatis labore
                  reprehenderit sapiente provident vel maxime.
                </p>
                <ul className="m-0 p-0">
                  <li>Morbi fermentum felis nec</li>
                  <li>Fermentum felis nec gravida tempus.</li>
                  <li>Morbi fermentum felis nec</li>
                  <li>Fermentum felis nec gravida tempus.</li>
                  <li>Morbi fermentum felis nec</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="skills">
                <h5>Personal Skills</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Nobis repellat inventore at praesentium perspiciatis labore
                  reprehenderit sapiente provident vel maxime.
                </p>
                <div className="skill-bars">
                  {/*<!-- SkillBar -->*/}
                  <div className="skill-bar">
                    {/*<!-- Title -->*/}
                    <p>Wordpress</p>
                    {/*<!-- Progress Bar -->*/}
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: '90%' }}
                      >
                        <span>90%</span>
                      </div>
                    </div>
                  </div>
                  {/*<!-- SkillBar -->*/}
                  <div className="skill-bar">
                    {/*<!-- Title -->*/}
                    <p>PHP</p>
                    {/*<!-- Progress Bar -->*/}
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: '75%' }}
                      >
                        <span>75%</span>
                      </div>
                    </div>
                  </div>
                  {/*<!-- SkillBar -->*/}
                  <div className="skill-bar">
                    {/*<!-- Title -->*/}
                    <p>Javascript</p>
                    {/*<!-- Progress Bar -->*/}
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: '60%' }}
                      >
                        <span>60%</span>
                      </div>
                    </div>
                  </div>
                  {/*<!-- SkillBar -->*/}
                  <div className="skill-bar">
                    {/*<!-- Title -->*/}
                    <p>HTML</p>
                    {/*<!-- Progress Bar -->*/}
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: '80%' }}
                      >
                        <span>80%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
