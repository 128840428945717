import { Link } from 'react-router-dom';
import { DATA_IMAGE_URL } from '../config/constants';

export const CardBody = ({ player, id }) => {
  const profile_picture = player?.profile_picture?.data
    ? `${DATA_IMAGE_URL}${player?.profile_picture?.data?.attributes?.url}`
    : `${DATA_IMAGE_URL}/uploads/default_pic_902a7f2c5c_6c4f80ae05.jpg`;
  return (
    <>
      <div className="image" style={{ height: '250px' }}>
        <img
          src={profile_picture}
          alt="speaker"
          height={150}
          className="img-fluid"
          style={{ width: '250px' }}
        />
        <div className="primary-overlay"></div>
        <div className="socials">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="/">
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/">
                <i className="fa fa-pinterest"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="content text-center">
        <h5>
          <Link to={`${id}`}>{player?.player_name}</Link>
        </h5>

        <ul
          className="list-inline"
          style={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          <li className="list-inline-item">
            <i className="fa fa-user mr-2"></i>
            {player?.age}
          </li>
          <li className="list-inline-item">
            <i className="fa fa-diamond mr-2"></i>
            {player?.age}
          </li>
        </ul>
        <p>
          <span>
            {' '}
            <i className="fa fa-group mr-2"></i>{' '}
            {player?.groups?.data[0]?.attributes.groupName}
          </span>
        </p>
        {/* <span className="alternate">{player?.groups}</span> */}
      </div>
    </>
  );
};
