import { useParams } from 'react-router-dom';

import { useGetAllGroupsQuery } from '../config/SeasonsApi';
import { Group } from '../components/Group';
import { useEffect, useState } from 'react';

export const SeasonPage = () => {
  const { id } = useParams() || 1;

  const { status, data: seasonData } = useGetAllGroupsQuery(id);

  return (
    <div>
      <section className="section pricing">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title">
                <h3>
                  Season{' '}
                  <span className="alternate">
                    {seasonData?.season_name.split(' ')[1]}
                  </span>
                </h3>
                <p>
                  Information about this if it is there in description. The
                  Season has started on {seasonData?.startdate}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {seasonData?.groups?.map((data) => (
              <Group group={data.attributes} key={data.id} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
