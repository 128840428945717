import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const MatchesApi = createApi({
  reducerPath: 'matchesApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:1337/api/matches/' }),
  endpoints: (builder) => ({
    getAllMatches: builder.query({
      query: (groupId) => ({
        url: `?populate[teams][populate]=*&populate[group]=*`,
        params: { groupId }
      }),
      transformResponse: (response) => {
        const modified = response.data.map((resp) => {
          const data = { ...resp.attributes, id: resp.id };
          return data;
        });

        const playerList = [];

        modified.forEach((obj) => {
          obj.teams.forEach((team) => {
            playerList.push({
              player: team.player.data.attributes.player_name,
              wins: team.sets.reduce(
                (prev = 0, curr) => prev + parseInt(curr.score),
                0
              ),
              losses: team.sets.reduce(
                (prev = 0, curr) => prev + parseInt(curr.losing_score),
                0
              ),
              points: team.is_winner ? 4 : 1
            });
          });
        });

        return { data: modified, playerList: playerList };
      }
    })

    // getAllMatches: builder.query({
    //   query: () => ({
    //     url: `?fields[0]=*&populate[group][fields][0]=*&populate[player][fields][1]=*&populate[opponent][fields][1]=*`,
    //     params: {}
    //   }),
    //   transformResponse: (response) => {
    //     const resp = response.data.map((resp) => {
    //       const data = { ...resp.attributes, id: resp.id };

    //       for (const prop in data) {
    //         if (data[prop].hasOwnProperty('data')) {
    //           data[prop] = data[prop].data?.attributes;
    //         }
    //       }

    //       return data;
    //     });

    //     // For grouping player Data will need later.
    //     // const processedData = Object.groupBy(resp, (match) => {
    //     //   return match?.player?.player_name;
    //     // // });

    //     // return processedData;

    //     return resp;
    //   }
    // })
  })
});

export const { useGetAllMatchesQuery } = MatchesApi;
